var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "callDelivery" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "呼叫配送",
            visible: _vm.openDialog,
            width: "760px",
            modal: true,
            "close-on-click-modal": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-width": "80px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { label: "平台应用" },
                    },
                    [
                      _c(
                        "div",
                        {
                          attrs: { size: "mini" },
                          on: { change: _vm.handLogisticsApp },
                        },
                        [
                          _vm.hasOpened.length > 0
                            ? _c(
                                "div",
                                { staticClass: "x-f" },
                                _vm._l(_vm.cityExpress, function (item, index) {
                                  return _c("div", { key: index }, [
                                    (
                                      _vm.fetchType == 3
                                        ? item.type == 8 &&
                                          _vm.hasOpened.includes(item.type)
                                        : item.type != 8 &&
                                          _vm.hasOpened.includes(item.type)
                                    )
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "distribution x-fc",
                                            class: {
                                              active:
                                                item.type ==
                                                _vm.form.logisticsAppType,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.activeChange(
                                                  item.type
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: item.imgUrl,
                                                alt: "#",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                }),
                                0
                              )
                            : _c("div", [_vm._v(" 暂未开通同城配送/快递 ")]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _vm.form.logisticsAppType == 8
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "快递", prop: "deliveryItem" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    size: "mini",
                                    filterable: "",
                                    clearable: "",
                                  },
                                  on: { change: _vm.deliveryItemChange },
                                  model: {
                                    value: _vm.form.deliveryItem,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "deliveryItem", $$v)
                                    },
                                    expression: "form.deliveryItem",
                                  },
                                },
                                _vm._l(_vm.list, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.deliveryName,
                                      value: item,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.logisticsAppType == 8
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "服务类型",
                                prop: "wxExpressService",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    size: "mini",
                                    filterable: "",
                                    clearable: "",
                                    "value-key": "service_type",
                                  },
                                  model: {
                                    value: _vm.form.wxExpressService,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "wxExpressService",
                                        $$v
                                      )
                                    },
                                    expression: "form.wxExpressService",
                                  },
                                },
                                _vm._l(_vm.deliveryList, function (item) {
                                  return _c("el-option", {
                                    key: item.service_type,
                                    attrs: {
                                      label: item.service_name,
                                      value: item,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _vm.form.logisticsAppType == 8
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "bizId" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "150px" },
                                attrs: { size: "mini", disabled: "" },
                                model: {
                                  value: _vm.form.bizId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "bizId", $$v)
                                  },
                                  expression: "form.bizId",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.logisticsAppType == 8
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "deliveryId" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "150px" },
                                attrs: { size: "mini", disabled: "" },
                                model: {
                                  value: _vm.form.deliveryId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "deliveryId", $$v)
                                  },
                                  expression: "form.deliveryId",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单编号" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.reserveOrderInfo.billNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.reserveOrderInfo, "billNo", $$v)
                              },
                              expression: "reserveOrderInfo.billNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "下单时间" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.reserveOrderInfo.billDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.reserveOrderInfo, "billDate", $$v)
                              },
                              expression: "reserveOrderInfo.billDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "取货门店" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.reserveOrderInfo.fetchShopName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.reserveOrderInfo,
                                  "fetchShopName",
                                  $$v
                                )
                              },
                              expression: "reserveOrderInfo.fetchShopName",
                            },
                          }),
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.reserveOrderInfo.fetchTypeName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.reserveOrderInfo,
                                  "fetchTypeName",
                                  $$v
                                )
                              },
                              expression: "reserveOrderInfo.fetchTypeName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "送货时间" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.reserveOrderInfo.fetchDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.reserveOrderInfo, "fetchDate", $$v)
                              },
                              expression: "reserveOrderInfo.fetchDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "会员名称" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.reserveOrderInfo.receiverName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.reserveOrderInfo,
                                  "receiverName",
                                  $$v
                                )
                              },
                              expression: "reserveOrderInfo.receiverName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "电话" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "150px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.reserveOrderInfo.receiverPhone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.reserveOrderInfo,
                                  "receiverPhone",
                                  $$v
                                )
                              },
                              expression: "reserveOrderInfo.receiverPhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { size: "mini", disabled: "" },
                            model: {
                              value: _vm.reserveOrderInfo.receiverAddress,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.reserveOrderInfo,
                                  "receiverAddress",
                                  $$v
                                )
                              },
                              expression: "reserveOrderInfo.receiverAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer-btn" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { type: "primary", disabled: _vm.disabled },
                  on: {
                    click: function ($event) {
                      return _vm.handleClose(1)
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "cancelBtn",
                  on: {
                    click: function ($event) {
                      return _vm.handleClose(2)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }