<template>
  <!-- 呼叫配送弹窗 -->
  <div class="callDelivery">
    <el-dialog title="呼叫配送" :visible.sync="openDialog" width="760px" :modal="true" :close-on-click-modal="false"
      :before-close="handleClose">
      <div class="content">
        <el-form label-width="80px" :model="form" :rules="rules" ref="form">
          <el-form-item label="平台应用" style="margin-top:20px">
             <!-- v-model="form.logisticsAppType" -->
            <div size="mini" @change="handLogisticsApp">
               <!-- v-if="fetchType==2" -->
              <div class="x-f" v-if="hasOpened.length>0">
                <div v-for="(item,index) in cityExpress" :key="index">
                  <div class="distribution x-fc" :class="{active:item.type==form.logisticsAppType}" @click="activeChange(item.type)"  v-if="fetchType==3?(item.type==8&&hasOpened.includes(item.type)):(item.type!=8&&hasOpened.includes(item.type))">
                  <img :src="item.imgUrl" alt="#">
                  </div>
                </div>
              </div>
                <div v-else>
                暂未开通同城配送/快递
                </div>
              <!-- <el-tab-pane :label="8" v-if="fetchType==3&&hasOpened.includes(8)">微信快递</el-tab-pane>
              <el-tab-pane :label="111" v-if="hasOpened.length==0">暂未开通同城配送/快递</el-tab-pane> -->
            </div>
          </el-form-item>
          <div class="x-f">
         <el-form-item label="快递" v-if="form.logisticsAppType==8" prop="deliveryItem">
            <el-select v-model="form.deliveryItem" placeholder="请选择" size="mini" filterable clearable
              @change="deliveryItemChange">
              <el-option :label="item.deliveryName" :value="item" v-for="(item,index) in list" :key="index" />
            </el-select>
          </el-form-item>
          <el-form-item label="服务类型" v-if="form.logisticsAppType==8" prop="wxExpressService">
            <el-select v-model="form.wxExpressService" placeholder="请选择" size="mini" filterable clearable
              value-key='service_type'>
              <el-option v-for="item in deliveryList" :key="item.service_type" :label="item.service_name"
                :value="item" />
            </el-select>
          </el-form-item>
          </div>
          <div class="x-f">
          <el-form-item label="bizId" v-if="form.logisticsAppType==8">
            <el-input v-model="form.bizId" size="mini" style="width:150px" disabled />
          </el-form-item>
             <el-form-item label="deliveryId" v-if="form.logisticsAppType==8">
            <el-input v-model="form.deliveryId" size="mini" style="width:150px" disabled />
          </el-form-item>
          </div>
            <div class="x-f">
          <el-form-item label="订单编号">
            <el-input v-model="reserveOrderInfo.billNo" size="mini" style="width:150px" disabled />
          </el-form-item>
          <el-form-item label="下单时间">
            <el-input v-model="reserveOrderInfo.billDate" size="mini" style="width:150px" disabled />
          </el-form-item>
           <el-form-item label="取货门店">
            <el-input v-model="reserveOrderInfo.fetchShopName" size="mini" style="width:100px" disabled />
            <el-input v-model="reserveOrderInfo.fetchTypeName" size="mini" style="width:100px" disabled />
          </el-form-item>
          </div>
           <div class="x-f" >
          <el-form-item label="送货时间">
            <el-input v-model="reserveOrderInfo.fetchDate" size="mini" style="width:150px" disabled />
          </el-form-item>
          <el-form-item label="会员名称">
            <el-input v-model="reserveOrderInfo.receiverName" size="mini" style="width:150px" disabled />
          </el-form-item>
          <el-form-item label="电话">
            <el-input v-model="reserveOrderInfo.receiverPhone" size="mini" style="width:150px" disabled />
          </el-form-item>
          </div>
            <div class="x-f">
           <el-form-item label="地址">
            <el-input v-model="reserveOrderInfo.receiverAddress" size="mini" disabled style="width:500px"/>
          </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="footer-btn">
        <el-button style="margin-right:10px" type="primary" @click="handleClose(1)" :disabled='disabled'>确定</el-button>
        <el-button class="cancelBtn" @click="handleClose(2)">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCallDelivery,
  getAllAccount,
  getOpenAppTypes
} from '@/api/shop/orderCenter/orderManage' //门店中台
export default {
  name: "CallDelivery",
  props: {
    // 弹窗开关
    openDialog: {
      type: Boolean,
      default: false,
    },
    billId: {
      type: String,
      default: '',
    },
    shopId: {
      type: [String, Number],
      default: '',
    },
    fetchType: {
      type: [Number, String],
      default: 2,
    },
   reserveOrderInfo: {
      type: Object,
      default: ()=>{},
    },
  },
  data() {
    return {
     activeIndex:null,
     cityExpress: [
        {
          type: 1,
          title: '达达',
          imgUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-05/2d1aead3b1fa4c8fb30ef4dc071bd51a.png',
        },
        {
          type: 2,
          title: 'UU跑腿',
          imgUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-05/fcc6ab899b134deea955286683c19532.png',
        },
        {
          type: 3,
          title: '蜂鸟',
          imgUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-05/4e995c091da74647bc139e7778b814f8.png',
        },
        {
          type: 4,
          title: '顺丰同城',
          imgUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-05/61c0613b04434cd3aad45a9197cbf5e7.png',
        },
        {
          type: 5,
          title: '闪送',
          imgUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-05/dad2f9a1b7ed441d85de8ddce1554d3b.png',
        },
        {
          type: 7,
          title: '微信同城',
          imgUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-05/605b967b01e148edb3f3923f1349a407.png',
        },
       {
          type: 8,
          title: '微信快递',
          imgUrl: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-07-22/cc6ac952775c459e901979f64bf20550.png',
        },
      ],
      disabled: false,
      hasOpened: [],
      deliveryList: [],
      list: [],
      form: {
        logisticsAppType: 1
      },
      //校验规则
      rules: {
        deliveryItem: [
          {
            required: true,
            message: "请选择快递",
            trigger: ["blur", "change"],
          },
        ],
        wxExpressService: [
          {
            required: true,
            message: "请选择服务类型",
            trigger: ["blur", "change"],
          },
        ],
      }
    };
  },
  watch: {
    async openDialog(val) {
      if (val) {
        this.disabled = false
        this.form.billId = this.billId
        this.form.shopId = this.shopId
        let res1 = await getOpenAppTypes()
        this.hasOpened = res1.data || []
        let arr1 = [1, 2, 3, 4, 5, 7]
        let containsAny = arr1.some(value => this.hasOpened.includes(value));
        let containsAny2 = this.hasOpened.includes(8);
        if (this.fetchType == 2 && containsAny) {
          this.hasOpened.forEach(v => {
            if (v != 8) {
              this.form.logisticsAppType = v
            }
          })
        } else if (this.fetchType == 3 && containsAny2) {
          this.form.logisticsAppType = 8
          this.handLogisticsApp(8)
        } else {
          this.form.logisticsAppType = 111
        }
        console.log('sa',this.form.logisticsAppType,this.fetchType,this.hasOpened);
      } else {
        this.reset()
      }
    }
  },
  methods: {
    activeChange(index){
      this.form.logisticsAppType=index
    },
    deliveryItemChange(value) {
      this.deliveryList = value.serviceType
      if (this.form.logisticsAppType == 8) {
        this.form.bizId = value.bizId
        this.form.deliveryId = value.deliveryId
      }
    },
    async handLogisticsApp(value) {
      if (value == 8) {
        const res = await getAllAccount()
        this.list = res.data?.list || []
        console.log('res', res);
      }
    },
    async handleClose(value) {
      if (value == 1) {
        if (this.form.logisticsAppType == 111) {
          return this.$message.error("暂未开通同城配送/快递 ！")
        }
        this.$refs["form"].validate(async (valid) => {
          if (valid) {
              if (this.disabled) {
            return
        }
           this.disabled = true
            try {
              this.form.isAssemble=true
              await getCallDelivery(this.form)
              this.$emit("update:openDialog", false);
              this.$emit("getControls", 14);
              this.disabled = false
            } catch (error) {
              // this.$message.error(error);
              this.disabled = false
            }
          }
        });
      } else {
        this.$emit("update:openDialog", false);
      }
    },
    reset() {
      this.form = {
        logisticsAppType: 1
      };
      this.$refs.form.resetFields();
    }
  }
};
</script>

<style lang="scss" scoped>
.callDelivery {
  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
.distribution{
  margin:0px 15px 0 0;
  border: 1px solid #dcdfe6;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 3px;
}
.distribution:hover {
   margin:0px 15px 0 0;
  border: 1px solid #2e95ff;
  padding: 10px 10px; 
  border-radius: 3px;
}
.active{
  border: 1px solid #2e95ff;
}
  //底部按钮
  .footer-btn {
    // height: 60px;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    border-top: 1px solid #e3e9ee;
    padding: 1% 3%;
  }
}
</style>
