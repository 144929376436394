<template>
  <!-- 预订弹窗 -->
  <div class="reserveDialog">
    <c-dialog title="预订" :width="700" :showDialog.sync="openDialog" :beforeClose="handleClose">
      <template #content>
        <div class="content">
          <el-scrollbar>
            <el-form label-width="100px" :model="billOrderReq" :rules="rules" ref="form">
              <el-form-item label="提货模式">
                <div class="tabs x-x">
                  <div class="tab x-fc curP" :class="{ active: billOrderReq.fetchType == 1 }"
                    @click="getCurDeliveryTab(1)">
                    门店自提
                  </div>
                  <div class="tab x-fc curP" :class="{ active: billOrderReq.fetchType == 2 }"
                    @click="getCurDeliveryTab(2)">
                    商家配送
                  </div>
                  <div class="tab x-fc curP" :class="{ active: billOrderReq.fetchType == 3 }"
                    @click="getCurDeliveryTab(3)">
                    快递配送
                  </div>
                </div>
              </el-form-item>
              <!-- 门店自提 -->
              <div class="storeSelfPickup" v-show="billOrderReq.fetchType == 1">
                <div class="x-f">
                  <el-form-item label="客户名称" prop="receiverName">
                    <el-input size="mini" placeholder="请输入客户名称" class="name" v-model="billOrderReq.receiverName"
                      id="receiverName"></el-input>
                  </el-form-item>
                  <el-form-item label="联系电话" prop="receiverPhone">
                    <el-input size="mini" placeholder="请输入联系电话" class="phone"
                      v-model="billOrderReq.receiverPhone"></el-input>
                  </el-form-item>
                </div>
                <div class="x-f">
                  <el-form-item label="取货门店" prop="fetchShopId">
                    <SelectRemote @selectChange="changeFetchShop" v-model="billOrderReq.fetchShopId"
                      style="width: 250px" :option="$select({
                    key: 'listShop',
                  }).option
                " />
                  </el-form-item>
                  <el-form-item label="生产部门" prop="produceDeptId">
                    <el-select size="mini" v-model="billOrderReq.produceDeptId" placeholder="请选择生产部门" filterable
                      clearable>
                      <el-option v-for="item in decorationRoomList" :key="item.decorationRoomId"
                        :label="item.decorationRoomName" :value="item.decorationRoomId">
                        <div class="x-bc">
                          <span> {{ item.decorationRoomName }}</span>
                        </div>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="x-f">
                  <el-form-item label="取货时间" prop="fetchDate">
                    <el-date-picker size="mini" v-model="billOrderReq.fetchDate" value-format="yyyy-MM-dd HH:mm:ss"
                      type="date" placeholder="选择日期" :picker-options="pickerOptions" @change="fetchDateChange">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item prop="fetchTime" label-width="0" style="margin-left:10px">
                    <el-select size="mini" v-model="billOrderReq.fetchTime" placeholder="请选择取货时间段" filterable clearable>
                      <el-option v-for="item in fetchTimeList" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <el-form-item label="提醒时间">
                  <el-input size="mini" v-model="billOrderReq.fetchRemindHour" controls-position="right" :min="1"
                    type="number" class="marR10" />
                  <span>小时</span>
                </el-form-item>
                  <div class="x-f">
                <el-form-item label="手工单据号">
                  <el-input
                    placeholder="请输入手工单据号"
                    v-model="billOrderReq.handBillNo"
                    :maxlength="40"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="磅数">
                  <el-input
                    placeholder="请输入磅数"
                    v-model="billOrderReq.handPointSize"
                    :maxlength="40"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="x-f">
                <el-form-item label="尺寸">
                  <el-input
                    placeholder="请输入尺寸"
                    v-model="billOrderReq.handSize"
                    :maxlength="40"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="样式">
                  <el-input
                    placeholder="请输入样式"
                    v-model="billOrderReq.handStyle"
                    :maxlength="40"
                  >
                  </el-input>
                </el-form-item>
              </div>
                <el-form-item label="备注">
                  <el-input size="mini" class="remark" type="textarea" :rows="2" placeholder="请输入备注"
                    v-model="billOrderReq.remark" />
                </el-form-item>
                <el-form-item label="上传图片">
                  <AccessoryUpload listType="picture-card" :limit="10" title="" @getFileItems="getFileItemsData"
                    @delFileItems="delFileItems" :fileList="billOrderReq.imageUrls.map(x=>({ url: x}))" :fileSize="1"/>
                </el-form-item>
                <el-scrollbar></el-scrollbar>
              </div>
              <!-- 商家配送 -->
              <div class="merchantDelivery" v-show="billOrderReq.fetchType == 2">
                <div class="x-f">
                  <el-form-item label="收货人" prop="receiverName">
                    <el-input size="mini" placeholder="请输入收货人" class="name"
                      v-model="billOrderReq.receiverName"></el-input>
                  </el-form-item>
                  <el-form-item label="收货人电话" prop="receiverPhone">
                    <el-input size="mini" placeholder="请输入收货人电话" class="phone"
                      v-model="billOrderReq.receiverPhone"></el-input>
                  </el-form-item>
                </div>
                <div class="x-f">
                  <el-form-item label="收货人地址" prop="receiverRegionId">
                    <el-cascader :options="allRegionList" :props="defaultProp" v-model="billOrderReq.receiverRegionId"
                      filterable clearable @change="designerChange" ref="cascader">
                    </el-cascader>
                  </el-form-item>
                  <el-form-item label="详细地址" prop="receiverRegionAddress">
                    <el-input size="mini" placeholder="请输入详细地址" v-model="billOrderReq.receiverRegionAddress"></el-input>
                  </el-form-item>
                </div>
                <div class="x-f">
                  <el-form-item label="配送门店" prop="fetchShopId">
                    <SelectRemote @selectChange="changeFetchShop" v-model="billOrderReq.fetchShopId"
                      style="width: 250px" :option="$select({
                    key: 'listShop',
                  }).option
                " />
                  </el-form-item>

                  <el-form-item label="生产部门" prop="produceDeptId">
                    <!--  no-data-text="请检查您的生产部门是否存在/相关方案是否开启!谢谢!" -->
                    <el-select size="mini" v-model="billOrderReq.produceDeptId" placeholder="请选择生产部门" filterable
                      clearable>
                      <el-option v-for="item in decorationRoomList" :key="item.decorationRoomId"
                        :label="item.decorationRoomName" :value="item.decorationRoomId">
                        <div class="x-bc">
                          <span> {{ item.decorationRoomName }}</span>
                        </div>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="x-f">
                  <el-form-item label="配送时间" prop="fetchDate">
                    <el-date-picker size="mini" value-format="yyyy-MM-dd HH:mm:ss" v-model="billOrderReq.fetchDate"
                      type="date" placeholder="选择日期" :picker-options="pickerOptions" @change="fetchDateChange">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item prop="fetchTime" label-width="0" style="margin-left:10px">
                    <el-select size="mini" v-model="billOrderReq.fetchTime" placeholder="请选择配送时间段" filterable clearable>
                      <el-option v-for="item in fetchTimeList" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <el-form-item label="配送方式">
                  <el-radio-group v-model="billOrderReq.deliveryMode">
                    <el-radio :label="0">自营配送</el-radio>
                    <el-radio :label="1">众包配送</el-radio>
                    <el-radio :label="2">美团专送</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="提醒时间">
                  <el-input size="mini" v-model="billOrderReq.fetchRemindHour" controls-position="right" :min="1"
                    type="number" class="marR10"></el-input>
                  <span>小时</span>
                </el-form-item>
                   <div class="x-f">
                <el-form-item label="手工单据号">
                  <el-input
                    placeholder="请输入手工单据号"
                    v-model="billOrderReq.handBillNo"
                    :maxlength="40"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="磅数">
                  <el-input
                    placeholder="请输入磅数"
                    v-model="billOrderReq.handPointSize"
                    :maxlength="40"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="x-f">
                <el-form-item label="尺寸">
                  <el-input
                    placeholder="请输入尺寸"
                    v-model="billOrderReq.handSize"
                    :maxlength="40"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="样式">
                  <el-input
                    placeholder="请输入样式"
                    v-model="billOrderReq.handStyle"
                    :maxlength="40"
                  >
                  </el-input>
                </el-form-item>
              </div>
                <el-form-item label="备注">
                  <el-input size="mini" class="remark" type="textarea" :rows="2" placeholder="请输入备注"
                    v-model="billOrderReq.remark">
                  </el-input>
                </el-form-item>
                <el-form-item label="上传图片">
                  <AccessoryUpload listType="picture-card" :limit="10" title="" @getFileItems="getFileItemsData"
                    @delFileItems="delFileItems" :fileList="billOrderReq.imageUrls.map(x=>({ url: x}))" :fileSize="1"/>
                </el-form-item>
              </div>
              <!-- 快递配送 -->
              <div class="merchantDelivery" v-show="billOrderReq.fetchType == 3">
                <div class="x-f">
                  <el-form-item label="收货人" prop="receiverName">
                    <el-input size="mini" placeholder="请输入收货人" class="name"
                      v-model="billOrderReq.receiverName"></el-input>
                  </el-form-item>
                  <el-form-item label="收货人电话" prop="receiverPhone">
                    <el-input size="mini" placeholder="请输入收货人电话" class="phone"
                      v-model="billOrderReq.receiverPhone"></el-input>
                  </el-form-item>
                </div>
                <div class="x-f">
                  <el-form-item label="收货人地址" prop="receiverRegionId">
                    <el-cascader :options="allRegionList" :props="defaultProp" v-model="billOrderReq.receiverRegionId"
                      filterable clearable @change="designerChange" ref="cascader">
                    </el-cascader>
                  </el-form-item>
                  <el-form-item label="详细地址" prop="receiverRegionAddress">
                    <el-input size="mini" placeholder="请输入详细地址" v-model="billOrderReq.receiverRegionAddress"></el-input>
                  </el-form-item>
                </div>
                <div class="x-f">
                  <el-form-item label="配送门店" prop="fetchShopId">
                    <SelectRemote @selectChange="changeFetchShop" v-model="billOrderReq.fetchShopId"
                      style="width: 250px" :option="$select({
                    key: 'listShop',
                  }).option
                " />
                  </el-form-item>

                  <el-form-item label="生产部门" prop="produceDeptId">
                    <!--  no-data-text="请检查您的生产部门是否存在/相关方案是否开启!谢谢!" -->
                    <el-select size="mini" v-model="billOrderReq.produceDeptId" placeholder="请选择生产部门" filterable
                      clearable>
                      <el-option v-for="item in decorationRoomList" :key="item.decorationRoomId"
                        :label="item.decorationRoomName" :value="item.decorationRoomId">
                        <div class="x-bc">
                          <span> {{ item.decorationRoomName }}</span>
                        </div>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                   <div class="x-f">
                <el-form-item label="手工单据号">
                  <el-input
                    placeholder="请输入手工单据号"
                    v-model="billOrderReq.handBillNo"
                    :maxlength="40"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="磅数">
                  <el-input
                    placeholder="请输入磅数"
                    v-model="billOrderReq.handPointSize"
                    :maxlength="40"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="x-f">
                <el-form-item label="尺寸">
                  <el-input
                    placeholder="请输入尺寸"
                    v-model="billOrderReq.handSize"
                    :maxlength="40"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="样式">
                  <el-input
                    placeholder="请输入样式"
                    v-model="billOrderReq.handStyle"
                    :maxlength="40"
                  >
                  </el-input>
                </el-form-item>
              </div>
                <el-form-item label="备注">
                  <el-input size="mini" class="remark" type="textarea" :rows="2" placeholder="请输入备注"
                    v-model="billOrderReq.remark">
                  </el-input>
                </el-form-item>
                <el-form-item label="上传图片">
                  <AccessoryUpload listType="picture-card" :limit="10" title="" @getFileItems="getFileItemsData"
                    @delFileItems="delFileItems" :fileList="billOrderReq.imageUrls.map(x=>({ url: x}))" :fileSize="1"/>
                </el-form-item>
              </div>
            </el-form>
          </el-scrollbar>
        </div>
      </template>
      <template #buttonList>
        <el-button type="primary" @click="handleClose(1)">确定</el-button>
        <el-button class="cancelBtn" @click="handleClose(2)">取消</el-button>
      </template>
    </c-dialog>
  </div>
</template>
<script>
import {
  productionDepartmentList,  //获取生产裱花间门店
} from '@/api/shop/orderCenter/orderManage' //门店中台
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import AccessoryUpload from "@/views/components/accessoryUpload/index.vue"; //上传图片/附件组件
import { getAllRegion } from "@/api/goods/region";
import { deepCopy } from '@/utils'
export default {
  name: 'ReserveDialog',
  components: { AccessoryUpload, SelectRemote, CDialog },
  props: {
    // 弹窗开关
    openDialog: {
      type: Boolean,
      default: false
    },
    orderData: {
      type: Object
    }
  },
  data() {
    return {
      //禁用当日之前的时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      defaultProduceShopForm: {
        keyName: 'reserveDefault', //唯一值
        produceShopId: undefined
      },
      //本店id
      shopId: undefined,
      //默认生产门店
      reserveProduceShopId: undefined,
      //取货时段
      fetchTimeList: [
        '00:00-00:30',
        '00:30-01:00',
        '01:00-01:30',
        '01:30-02:00',
        '02:00-02:30',
        '02:30-03:00',
        '03:00-03:30',
        '03:30-04:00',
        '04:00-04:30',
        '04:30-05:00',
        '05:00-05:30',
        '05:30-06:00',
        '06:00-06:30',
        '06:30-07:00',
        '07:00-07:30',
        '07:30-08:00',
        '08:00-08:30',
        '08:30-09:00',
        '09:00-09:30',
        '09:30-10:00',
        '10:00-10:30',
        '10:30-11:00',
        '11:00-11:30',
        '11:30-12:00',
        '12:00-12:30',
        '12:30-13:00',
        '13:00-13:30',
        '13:30-14:00',
        '14:00-14:30',
        '14:30-15:00',
        '15:00-15:30',
        '15:30-16:00',
        '16:00-16:30',
        '16:30-17:00',
        '17:00-17:30',
        '17:30-18:00',
        '18:00-18:30',
        '18:30-19:00',
        '19:00-19:30',
        '19:30-20:00',
        '20:00-20:30',
        '20:30-21:00',
        '21:00-21:30',
        '21:30-22:00',
        '22:00-22:30',
        '22:30-23:00',
        '23:00-23:30',
        '23:30-00:00'
      ],
      //初始化取货时段
      oldFetchTimeList: [
        '00:00-00:30',
        '00:30-01:00',
        '01:00-01:30',
        '01:30-02:00',
        '02:00-02:30',
        '02:30-03:00',
        '03:00-03:30',
        '03:30-04:00',
        '04:00-04:30',
        '04:30-05:00',
        '05:00-05:30',
        '05:30-06:00',
        '06:00-06:30',
        '06:30-07:00',
        '07:00-07:30',
        '07:30-08:00',
        '08:00-08:30',
        '08:30-09:00',
        '09:00-09:30',
        '09:30-10:00',
        '10:00-10:30',
        '10:30-11:00',
        '11:00-11:30',
        '11:30-12:00',
        '12:00-12:30',
        '12:30-13:00',
        '13:00-13:30',
        '13:30-14:00',
        '14:00-14:30',
        '14:30-15:00',
        '15:00-15:30',
        '15:30-16:00',
        '16:00-16:30',
        '16:30-17:00',
        '17:00-17:30',
        '17:30-18:00',
        '18:00-18:30',
        '18:30-19:00',
        '19:00-19:30',
        '19:30-20:00',
        '20:00-20:30',
        '20:30-21:00',
        '21:00-21:30',
        '21:30-22:00',
        '22:00-22:30',
        '22:30-23:00',
        '23:00-23:30',
        '23:30-00:00'
      ],
      //省市区
      defaultProp: {
        value: 'id',
        label: 'label',
        emitPath: false
      },
      //省市区
      allRegionList: [],
      //生产部门信息
      decorationRoomList: [],
      //预定信息
      billOrderReq: {
        fetchType: 1, //取货方式
        fetchRemindHour: 1, //提前几小时
        imageUrls: []
      },
      //校验规则
      rules: {
        receiverName: [
          {
            required: true,
            message: '请输入客户名称',
            trigger: ['blur', 'change']
          }
        ],
        receiverPhone: [
          {
            required: true,
            message: '请输入联系电话',
            trigger: ['blur', 'change']
          }
        ],
        fetchShopId: [
          {
            required: true,
            message: '请选择门店',
            trigger: ['blur', 'change']
          }
        ],
        produceDeptId: [
          {
            required: true,
            message: '请选择生产部门',
            trigger: ['blur', 'change']
          }
        ],
        fetchDate: [
          {
            required: true,
            message: '请选择日期',
            trigger: ['blur', 'change']
          }
        ],
        fetchTime: [
          {
            required: true,
            message: '请选择时间段',
            trigger: ['blur', 'change']
          }
        ],
        receiverRegionId: [
          {
            required: true,
            message: '请选择收货人地址',
            trigger: ['blur', 'change']
          }
        ],
        receiverRegionAddress: [
          {
            required: true,
            message: '请选择收货人详细地址',
            trigger: ['blur', 'change']
          }
        ]
      },
      value: undefined,
      value1: undefined
    }
  },
  async created() {
    //新增省市区
    const { data } = await getAllRegion()
    this.allRegionList = data
    //获取生产部门
    let res = await productionDepartmentList()
    this.decorationRoomList = res.data
    if (this.decorationRoomList.length >= 1) {
      //添加系统自动匹配生产部门
      this.decorationRoomList.unshift({
        decorationCaseId: "-1",
        decorationRoomId: -1,
        decorationRoomName: "系统自动匹配",
      })
    }
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.$nextTick(() => {
          this.billOrderReq = deepCopy(this.orderData)
          // billOrderReq.fetchTime
          console.log('billOrderReq', this.billOrderReq.fetchTime);
          this.fetchTimeList = deepCopy(this.oldFetchTimeList);
          this.handleRequireKey(this.billOrderReq.fetchType);
          
        })
      }
    },
    'billOrderReq.fetchType': {
      handler(val) {
        if (!val) return
        if (this.billOrderReq.fetchType == 2) {
          this.rules.receiverRegionId[0].required = true
          this.rules.receiverRegionAddress[0].required = true
        } else {
          this.rules.receiverRegionId[0].required = false
          this.rules.receiverRegionAddress[0].required = false
        }
      },
      immediate: true
    }
  },
  methods: {
    async designerChange(val) {
      this.billOrderReq.receiverRegionName = this.$refs['cascader']
        .getCheckedNodes()[0]
        .pathLabels.join('') //省市区名称
    },
    //选择取货时间
    fetchDateChange() {
      //判断是不是今天的日期
      const fetchDate = new Date(this.billOrderReq.fetchDate)
      const today = new Date()
      const isToday = fetchDate.toDateString() === today.toDateString()

      if (isToday) {
        this.getCurrentDate()
      } else {
        //取货时间段
        this.fetchTimeList = this.oldFetchTimeList
      }
    },
    //过滤时间
    getCurrentDate() {
      const currentTime = new Date()
      const currentHour = currentTime.getHours()
      const currentMinutes = currentTime.getMinutes()
      const currentTimeString = `${currentHour
        .toString()
        .padStart(2, '0')}:${currentMinutes.toString().padStart(2, '0')}`
      if (this.billOrderReq.fetchTime < currentTimeString) {
        this.billOrderReq.fetchTime = undefined
      }
      this.fetchTimeList = this.fetchTimeList.filter(timeSlot => {
        const [startTime, endTime] = timeSlot.split('-')
        return startTime > currentTimeString
      })
    },
    reset() {
      //当前门店
      this.shopId = undefined
      //取货时间段
      this.fetchTimeList = this.oldFetchTimeList
      //预定默认
      this.defaultProduceShopForm = {
        keyName: 'reserveDefault', //唯一值
        produceShopId: undefined
      }
      this.reserveProduceShopId = undefined
      this.billOrderReq = {
        fetchType: 1, //取货方式
        fetchRemindHour: 1, //提前几小时
        imageUrls: []
      }
      this.$refs.form.resetFields()
    },
    //生产部门
    async changeProduceChange(val) {
      this.defaultProduceShopForm.produceDepId = val
      this.decorationRoomList.forEach(item => {
        if (item.decorationRoomId != val) {
          this.$set(item, 'defaultValue', false)
        }
      })
    },
    //上传阿里图库
    getFileItemsData(value) {
      this.billOrderReq.imageUrls.push(value.accessUrl)
    },
    //删除阿里图库
    delFileItems(value) {
      const index = this.billOrderReq.imageUrls.indexOf(value)
      console.log(value, index, '删除')
      if (index > -1) {
        this.billOrderReq.imageUrls.splice(index, 1)
      }
    },
    handleRequireKey(value){
      if(value == 3){
        this.rules.fetchDate[0].required = false
        this.rules.fetchTime[0].required = false
      }else{
        this.rules.fetchDate[0].required = true
        this.rules.fetchTime[0].required = true
      }
    },
    //切换预定的类型
    getCurDeliveryTab(value) {
      // this.$set(this.billOrderReq,'fetchType',value)
      this.billOrderReq = {
        ...this.billOrderReq,
        deliveryMode: this.billOrderReq.deliveryMode || 0,
        fetchType: value, //取货方式
      }
      this.$refs.form.resetFields();
      this.handleRequireKey(value);
      // //取货时间
      // this.$set(this.billOrderReq, 'fetchTime', '17:00-17:30')
      // if (this.shopId) {
      //   this.$set(this.billOrderReq, 'fetchShopId', Number(this.shopId))
      //   this.shopList.forEach(v => {
      //     if (v.shopId == this.shopId) {
      //       this.$set(this.billOrderReq, 'fetchShopName', v.shopName)
      //     }
      //   })
      // }
      // if (this.reserveProduceShopId) {
      //   this.$set(this.billOrderReq, 'produceDeptId', this.reserveProduceShopId)
      // }
    },
    //关闭弹窗的回调
    handleClose(number) {
      console.log(this.billOrderReq, 'this.billOrderReq')
      if (number == 1) {
        this.$refs['form'].validate(async valid => {
          if (valid) {
            if (this.billOrderReq.fetchType == 1) {
              this.billOrderReq.receiverRegionId = undefined;
              this.billOrderReq.receiverRegionName = undefined;
              this.billOrderReq.receiverAddress = undefined;
              this.billOrderReq.receiverRegionAddress = undefined;
            }
            this.billOrderReq.receiverAddress =
              (this.billOrderReq.receiverRegionName || '') +
              (this.billOrderReq.receiverRegionAddress || '')
            this.$emit('billOrderReqCom', this.billOrderReq)
            this.$emit('update:openDialog', false)
          }
        })
      } else {
        this.$emit('billOrderReqCom')
        this.$emit('update:openDialog', false)
      }
    },
    //取货门店提名称
    changeFetchShop(val) {
      this.shopList.forEach(v => {
        if (v.shopId == val) {
          this.billOrderReq.fetchShopName = v.shopName
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reserveDialog {
  //滚动条
  .el-scrollbar {
    height: 100%;
    overflow: hidden;
    // 侧边滚动条显示
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    ::v-deep .el-scrollbar__bar.is-vertical {
      opacity: 1;
    }
  }
  .content {
    height: calc(100% - 60px);
    .el-form {
      padding: 20px;
      margin: 0 auto;
    }
    //配送模式
    .tabs {
      .tab {
        width: 30%;
        height: 5%;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        margin-right: 2%;
        &.active {
          border: 1px solid #2e95ff;
          background-color: #d5eaff;
          color: #409eff;
        }
      }
    }

    //时间
    ::v-deep .el-range-editor.el-input__inner {
      width: 100%;
    }
    //名称/电话/备注
    .name,
    .phone,
    .remark {
      width: 100%;
    }
    ::v-deep .el-select,
    .el-input,
    .el-cascader {
      width: 200px !important;
    }
    ::v-deep .el-input-number {
      width: 150px !important;
    }
  }
}
/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
